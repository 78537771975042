.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  min-height: 100vh;
}
.loginForm {
  width: 420px;
  margin: 0 auto;
  box-shadow: grey 0px 0px 10px;
}
